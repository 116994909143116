import styled from '@emotion/styled'
import { IconSeparator } from 'app/components/Common/IconSeparator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as StripeProps, Stripe } from './Stripe'

export interface Props {
  stripes: StripeProps[]
}

export const ColumnZigZag = memo(function ColumnZigZag({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      <IconSeparator />

      <Stripes row wrap>
        {stripes.map((item, index) => (
          <Stripe key={index} {...item} />
        ))}
      </Stripes>
    </Container>
  )
})

const Container = styled.section`
  margin: -7.5rem 0 15.3125rem;

  @media (max-width: 767px) {
    margin: 0 auto 5rem;
  }
`

const Stripes = styled(FlexBox)`
  margin-right: -3.75rem;
  padding: 0 12.639vw;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(70% - 3.75rem);
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 18.75rem;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding: 0 5vw;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 1.25rem;
    &:before {
      display: none;
    }
  }
`
